import React, { useState } from 'react'
import './tictactoe.css'

export default function Square({ xPos, yPos, board, handlePress }) {
  // check if box number is contained in either list. Show X or O accordingly
  const [pressed, setPressed] = useState(false)

  return (
    <div className="col square d-flex flex-column justify-content-center text-center" onClick={() => {
      if (!pressed) {
        handlePress(xPos, yPos)
      }
      setPressed(true)
    }}>
      {board[xPos][yPos]}
    </div>
  )
}