import React, { useState } from 'react'
import './tictactoe.css'

import Square from './square'

export default function TicTacToe() {
  const [board, setBoard] = useState([["","",""],["","",""],["","",""]])
  const [turn, setTurn] = useState("X")
  const [winner, setWinner] = useState("")
  const [moveCounter, setMoveCounter] = useState(0)

  const handleSquarePress = (xPos, yPos) => {
    if (turn === "X") {
      let newBoard = board;
      newBoard[xPos][yPos] = "X"
      setBoard(newBoard)
    } else {
      let newBoard = board;
      newBoard[xPos][yPos] = "O"
      setBoard(newBoard)
    }

    // toggle turn
    if (turn === "X") {
      setTurn("O")
    } else {
      setTurn("X")
    }

    checkForWin(xPos, yPos)
  }

  const checkForWin = (xPos, yPos) => {
    // check the column
    for (var i = 0; i < 3; i++) {
      let value = board[xPos][i];

      if (turn !== value) {
        break;
      }

      if (i === 2) {
        // if we got here we never found a value that wasn't the turn
        // that player won
        setWinner(turn)
      }
    }

    // check the row
    for (var i = 0; i < 3; i++) {
      let value = board[i][yPos];

      if (turn !== value) {
        break;
      }

      if (i === 2) {
        // if we got here we never found a value that wasn't the turn
        // that player won
        setWinner(turn)
      }
    }

    // check for draw
    if (moveCounter === 8) {
      // draw
      setWinner("CAT")
    } else {
      setMoveCounter(moveCounter+1)
    }
  }

  return (
    <div className="d-flex flex-column h-100 text-center justify-content-center" style={{ backgroundColor: "grey" }}>
      {winner}
      <div className="container game">
        <div className="row">
          <Square xPos={0} yPos={0} board={board} handlePress={handleSquarePress} />
          <Square xPos={1} yPos={0} board={board} handlePress={handleSquarePress} />
          <Square xPos={2} yPos={0} board={board} handlePress={handleSquarePress} />
        </div>
        <div className="row">
          <Square xPos={0} yPos={1} board={board} handlePress={handleSquarePress} />
          <Square xPos={1} yPos={1} board={board} handlePress={handleSquarePress} />
          <Square xPos={2} yPos={1} board={board} handlePress={handleSquarePress} />
        </div>
        <div className="row">
          <Square xPos={0} yPos={2} board={board} handlePress={handleSquarePress} />
          <Square xPos={1} yPos={2} board={board} handlePress={handleSquarePress} />
          <Square xPos={2} yPos={2} board={board} handlePress={handleSquarePress} />
        </div>
      </div>
    </div>
  )
}