import React, { useEffect, useState } from "react"
import './home.css'

import Header from "./header"


export default function Home() {
  const [isShrunk, setShrunk] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        setShrunk(window.scrollY > 100)
      }
        
      );
    }
  }, []);

  const infoBoxStyle = { 
    backgroundColor: "#404652", 
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    transition: "all 0.5s" 
  }

  return (
    <div className="container-fluid" style={{ color: "white" }}>
      <Header isShrunk={true} />
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column">
            <div 
              className="mx-5 mt-3 px-5 pb-3 pt-3 info-box" 
              style={infoBoxStyle}
              onMouseEnter={(e) => e.target.style.t}
            >
              <h1 className="display-4 item">Education</h1>
              <p className="lead item">
                <span className="fw-bold"> Northwest Nazarene University 2016-2020 </span> <br />
                <ul>
                  <li>Bachelor of Arts in Computer Science with a concentration in International Studies <span
                    className="fw-bold">(3.4 GPA)</span></li>
                  <li>Member of the Honors College</li>
                </ul>
              </p>
              <p className="lead item">
                <span className="fw-bold"> American Studies Program Fall 2018</span> <br />
                Spent the Fall Semester in Washington, DC living on Capitol Hill studying public policy with a focus on
                immigration.
                Also interned at the Office of Management and Budget in the Budget Systems Branch.
              </p>
            </div>
            <div className="mx-5 mt-3 mb-3 px-5 pt-3 pb-3 info-box" style={infoBoxStyle}>
              <h1 className="display-4 item">Projects</h1>
              <p className="lead item">
                <span className="fw-bold">HoopStats</span> || <a
                  href="https://play.google.com/store/apps/details?id=com.hooptime.hoopstats&hl=en_US">Play Store</a> || <u>Over 500 Monthly Users</u><br />
                React Native app for tracking your personal basketball stats. Available on Android.
              </p>
              <p className="lead item">
                <span className="fw-bold">Law School Status Checker</span> || <a
                  href="https://play.google.com/store/apps/details?id=com.jakeandtanner.lawschoolstatuschecker&hl=en_US&gl=US">Play
                  Store</a> <br />
                React Native app for tracking the status of multiple law school applications in the same place.
                Available on Android
              </p>
              <p className="lead item">
                <span className="fw-bold">Tab Duplicator Shortcut</span> || <a
                  href="https://chrome.google.com/webstore/detail/tab-duplicator-shortcut/dbpmgojhkioibaampnbpiiblpdjmkbjc?hl=en-US">Link</a> || <u>Over 200 Monthly Users</u><br />
                A Chrome extension that adds a shortcut to duplicate tabs. I developed this because I was often
                manually duplicating tabs and was surprised a shortcut did not exist in native Chrome, so I added it.
                The extension was written in JavaScript
              </p>
              <p className="lead item">
                <span className="fw-bold">Top Music App</span> || <a
                  href="https://top-music-app.vercel.app/">Link</a> || <a href="https://github.com/tannertracht/TopMusicApp">GitHub</a> <br />
                React TypeScript web app that displays tops albums and songs on iTunes
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="d-flex flex-column">
            <div className="mx-5 mt-3 mb-3 px-5 pb-3 pt-3 info-box" style={infoBoxStyle}>
              <h1 className="display-4 item">Experience</h1>
              <p className="lead item">
                <span className="fw-bold">Software Engineer I || RDG Filings || March 2020 - Current Position</span> <br />
                Developed software solutions to fit client needs. Worked with customers to gather requirements to build
                solutions that optimized workflows and improved user experience. Primarily worked with .Net Core as
                well as .Net Framework. Converted features from razor views to React components. Maintained and upgraded
                other legacy projects to more modern solutions.
              </p>
              <p className="lead item">
                <span className="fw-bold">Schweitzer Engineering Laboratories || Software Intern || April
                  2019 - March 2020 </span> <br />
                Worked on a continuous integration team in the Power Systems Division to automate testing of a
                customer facing software package. Led the design of a web app used by other team members for
                job queuing and configuration. The App consisted of a React front-end with a NodeJS/Express/Mongo backend.
                Wrote Powershell scripts to automate virtual machine system configurations.
                Participated in code reviews and sprint planning meetings.

              </p>
              <p className="lead item">
                <span className="fw-bold">Northwest Nazarene University || Research Fellow || May 2018 - April 2019
                </span> <br />
                Worked on a research team exploring solutions to automate the counting of fruits in orchards for local
                farmers.
                Led the research and implementation of machine learning algorithms to accomplish research goals.
                Used TensorFlow to implement Convolutional Neural Network to detect objects in an image.
                Presented results of research at an undergraduate research conference at Boise State University.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}