import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import Home from './components/home/home'
import TicTacToe from "./components/tictactoe/tictactoe"
import background from "./assets/electricbackground.png"

function App() {
  return (
    <Router>
      <div 
        className="d-flex flex-column h-100" 
        style={{ 
          backgroundImage: `url(${background})`, 
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundColor: "#101d38"
        }}
      >
        <Switch>
          <Route path="/tictactoe">
            <TicTacToe />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
