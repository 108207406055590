import React, { useState } from "react"

import headshotPic from "../../assets/headshot.jfif"


export default function Header({ isShrunk }) {
  const [imageHover, setImageHover] = useState(false)

  return (
    <div className="row py-3" style={{ backgroundColor: "#13151a", top: 0 }}>
      <div className="col text-start">
        <img className="rounded" style={{ transition: "all 0.5s" }} src={headshotPic} width={imageHover ? 300 : 80} height={imageHover ? 300 : 80} onMouseEnter={() => setImageHover(true)} onMouseLeave={() => setImageHover(false)} />
      </div>
      <div className="col text-center">
        <div className="d-flex flex-column h-100 justify-content-center">
          <div>
            <div className="display-3">Tanner Tracht</div>
          </div>
        </div>
      </div>
      <div className="col text-end">
        <div className="d-flex flex-column">
          <div><iframe src="https://open.spotify.com/embed/artist/3fDFs2VeAnvdXmdMO6H0E9" width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe></div>
        </div>
      </div>
    </div>
  )
}